import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
import md5 from 'js-md5'
import { encryptedDES,rsaSign } from './enDecrypt'

export const encryptionPassWord=(password)=>{
    // console.log(password);
        const privateKey = 'MIIEwAIBADANBgkqhkiG9w0BAQEFAASCBKowggSmAgEAAoIBAQDF1P/IjTzsjo3YpZUu02B9giwQQv+kaTCDyX2j6cMN2jnolEE4hKuqV64esL8GdDz4aAXg3aNIqTxfX+ZQziQ4clc97QwvZoiDlYw3hByg0KdfDmFWtci01U32H4endszUQfItv4GSRFt94tdZN0TMVejA2s/6N7NHn6vu8KBa+tkgN4k/X58LXZ8o+PjxURUdvwjm4NQaxyqVAyOq89FFrQJ4UAd1slTcjFYrl6jmKjEPE4fFfrlT/453CwF3yc6DWx5PcS6dnVOwdbRFRu0u8gwKA/ygfYgRrE+ZC43oDwpS1P5XsTuNWiq6Wue5P9fkO/7LlQF4apmeP+RdH9HrAgMBAAECggEBAKz9Gwloj3yfDuQBrpi6lyZInHU/0MEYhibHGwko0LodOAPrF7jvMhkLAKOvk+p13BavB2zhAfX4nmUbu46vIzcErMrmrIAKXZcKPwkQieQJcEGLlow2+yE9YCW23m0OUf/NBpMAYbHf+kzy/bHyxUMUYCm7bJ9081zO/445dU73fyBVlcLutVlf6WxJhcGm25pMG3vtnoFyS+w+diAbwaQIqZpWAEzU5K6ruHdN28SjLGmrbBA8imE330+w2+1RWKM192LEgjxQWqXlmchbNtXi1sLuBng0yQB/hi17pyxvidPRVnZBsVQBeo0iwMHn5SMbRCJgw1bHsjf4gFcWwNECgYEA5Rs6UMJYigczGIu0ZTi85eqNuoPkr+ZMSM7N1zxMABWSQk4f3UE4gYUlpLxfyf4DgLivpjtE7GU+dsR8Bukrb6qqNdNm23PDzjdeRMIVv8E1KFdpb5u7eoMVF8TOkCcgMVjA8X3B8XdnP0iblCktyF3Glu1fOSqw5Ass5/ArsLkCgYEA3Q31471LckXCxtnvzasYHLNjYlY9WMPxqYso0fLqXsDe40Nqf69bncOe9yT9hlRhxpyIzIO25FHAA3xKZDxxoKZaMFXtSu+M7pRzq693WfGMLTJv6PKJCZ2dq40PJEl7ci+MsZou3jR5U0tXffZSNFtl1QU3xYbujASXMw1NXcMCgYEA05MjdXUkosMnUqGhhGCzQXgsTnSC+lYpWIty2DPc068YFJdlIcSbVDELpGg55aXowjTMMS1G5zBQUQn+p7so28S1z2Nc94ie6I+FROYfg6f/WTPwMJuWtPrcvciIIeOf/TJDbECAnGdyN6Tr2BV3LDQ9sx+e8oIUPWR8oezPzDECgYEAoqUO8o3o4R9q/JyF0LG+f/+NdGE3qgQh3zlAw5kT0LvgQx7rUR9ZLW8tm3JgB2I2vseV78RJ4Rw1R1s/8Rh4ElfTrBTND4HKFBCuOvhTvJ35IZgmtyMcqTKLPBoBthVK+uz5fAOR0HSTgDHDIi8GtPc6qc25B2EMS4FlLm7vKbkCgYEAoTcFT2WEv+F8syuWVdoWPQ9k6otGG0DwkhbIU9sBssvvHnB16OWE12Wgx1aqv/QE2fHYRgKjUt2YcG8JLgvRRxPrbBpoE1/ML4F+lbWQhyHrm94iBNIZTjH6Y1HPctCueVGOPcuWGKrCGFr7XumPO42z/D4hUgKgHhvxYjzJcSo=';
        const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApNhal/RVrcok94YamGbsFMGCg+L39qsQWtUrwipCUKhqb5rOK1SyMojHwB4PTptEQgPanCtl3N7h1d3a0H27TAU8HGjdC+TKCCjTdZ1iJ77lGBwvcHId+s9v7+xTqv+yhcw6pzdAQtuTMjkWvIOkfm+W9MH5bJHQ9HHoTQ+hN+DmxUoZ9rrvC+8uSucl8rjmIVZ2trRpr1xo3JyAOw4KM9zZ0Sc8Lz64poLIHFZrdLDd/sYqte8GB1xQNy/EZPMkdxEYbOM2WLvGaUsVdKLC7OvhhzH7zhVJfVSyUW970TKsOcL88aWRjfrmrqJI4bd3p8EdFjAXK/QHibf6tjGgUwIDAQAB';
        // debugger
        let splitFlag = "#@#";

        let hA = md5(password);

        const uuid = uuidv4();
        let entry1 = new JSEncrypt();
        entry1.setPrivateKey(privateKey);
        
        
        let hASign = rsaSign(hA,privateKey);
        // console.log("hA:"+hASign)
        let mixPlaintext  = password+splitFlag+hASign;
        // console.log("c1:"+mixPlaintext)
        
        const DESKey =uuid.toString().replaceAll("-","");

        
        // console.log("DESKey:"+DESKey)

        let encryptDES = encryptedDES(mixPlaintext,DESKey);
        
        // console.log("encryptDES:"+encryptDES)
        let c1 = encryptDES.toString();
    
        let entry2 = new JSEncrypt();
        entry2.setPublicKey(publicKey);
    
        let c2 = entry2.encrypt(DESKey);
        
        // console.log("C2:"+c2)
        let msg  = c1+splitFlag+c2;
        return msg;
}


