import CryptoJS from 'crypto-js'
import {JSEncrypt} from "jsencrypt"
import { KEYUTIL, KJUR, hextob64 } from 'jsrsasign';



//加密
export function encryptedDES(data,DESKey) {
    const keyHex = CryptoJS.enc.Utf8.parse(DESKey);
    //模式为ECB padding为Pkcs7
    const encrypted = CryptoJS.DES.encrypt(JSON.stringify(data), keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    //加密出来是一个16进制的字符串
    return encrypted.ciphertext.toString();
}

export function rsaSign(plainText,priKey){
    //这里必须使用这个标准格式所有做了一个拼接
    var priK = "-----BEGIN PRIVATE KEY-----"+priKey+"-----END PRIVATE KEY-----";
    
    const rsa = KEYUTIL.getKey(priK);
    //后端算法与前端需要一致
    let sig = new KJUR.crypto.Signature({"alg": "SHA256withRSA"});
    // 初始化
    sig.init(rsa)
    // console.log("data:"+plainText);
    // 传入待加密字符串
    sig.updateString(plainText)
    // 生成密文
    let sign = hextob64(sig.sign());
    // console.log("sign:"+sign);
    // 对加密后内容进行URI编码
     sign = encodeURIComponent(sign);
    //  console.log("sign encode:"+sign);
     return sign;
  }